export const data = [
  {
    id: 0,
    title: "GambleGo",
    subtitle: "Web Application",
    technologies:
      "Angular, NX, Tailwind, Jest, NodeJS, Express, MongoDB, Retype",
    description:
      "GambleGo is an innovative online gambling platform offering thrilling and secure virtual betting. With a user-friendly interface, diverse games, and robust security, it's the go-to destination for exciting online entertainment.",
    isWebsite: true,
    link: null,
  },
  {
    id: 1,
    title: "Circadian",
    subtitle: "Mobile Application",
    technologies: "React-Native, Expo, NodeJS, Express, MongoDB",
    description:
      "A health and wellness app that boosts energy, focus, sleep quality, mood, body composition and manage daily activities It also includes a notification and alarm system to notify the user.",
    isWebsite: false,
    link: "https://play.google.com/store/apps/details?id=com.crestcoder.circadian&hl=en&gl=US",
  },
  {
    id: 2,
    title: "OAPF",
    subtitle: "Website",
    technologies:
      "ReactJS, Bootstrap, SCSS, NodeJS, Express, MongoDB, Firebase",
    description:
      " OAPF stands for Old Anandians Professional Forum. It's developed to manage the onboarding process to the Ananda College's Past Pupil's Association.",
    isWebsite: true,
    link: "https://www.oapf.org",
  },
  {
    id: 3,
    title: "Lavish Lashes",
    subtitle: "E-Commerce Website",
    technologies: "Shopify, Javascript, JQuery, HTML, SCSS, ReactJS, NodeJS",
    description:
      "Lavish Lashes is an e-commerce website that sells lashes products. It was built using shopify which includes a managing shopify app too.",
    isWebsite: true,
    link: "https://www.lavishlashes.com",
  },
  {
    id: 4,
    title: "KCP",
    subtitle: "Admin Dashboard",
    technologies: "SvelteJS, Javascript, SCSS, HTML, Laravel, MySQL, Docker",
    description:
      "KCP stands for Knowledge Creation Platform. It is developed to maintain and handle the POP data of multiple farming projects.",
    isWebsite: true,
    link: "https://kcp.widya.io/",
  },
  {
    id: 5,
    title: "PDKB",
    subtitle: "Admin Dashboard",
    technologies: "ReactJS, SCSS, Bootstrap, Laravel, MySQL, Docker",
    description:
      "PDKB stands for Pest and Disease Knowledge Base. It is developed to maintain and handle the pest and disease data of multiple farming projects.",
    isWebsite: true,
    link: null,
  },
  {
    id: 6,
    title: "Guardian",
    subtitle: "University Group Project",
    technologies: "React-Native, ReactJS, NodeJS, Redux, MySQL, Express, Expo",
    description:
      "The idea for this project was the child safety solution. This App can track parents their children when they are away from home. If there is an emergency situation children can directly inform parents or another third party.",
    isWebsite: true,
    link: null,
  },
  {
    id: 7,
    title: "VLanka.lk",
    subtitle: "University Group Project",
    technologies: "JavaEE, HTML, CSS, MySQL",
    description:
      "A Platform where Farmers can lend Lands to cultivate and Landowners can rent their Lands to Farmers, and also helps farmers with Eco-friendly farming methods.",
    isWebsite: true,
    link: null,
  },
  {
    id: 8,
    title: "Kipenzi",
    subtitle: "Client Project",
    technologies: "NextJS, SCSS, Bootstrap",
    description:
      "A single page web for a client who is running a animal caring center",
    isWebsite: true,
    link: null,
  },
  {
    id: 9,
    title: "Keo Plus AI",
    subtitle: "Portfolio Website",
    technologies: "Webflow, HTML, CSS",
    description: "A porfolio website for Keo Plus AI team",
    isWebsite: true,
    link: "https://www.keoplus.ai/",
  },
  {
    id: 10,
    title: "Muthukaruppan Chettiar Jewellers",
    subtitle: "Prototype Website",
    technologies: "React JS, SCSS, Bootstrap",
    description:
      "A Proposed E-Commerce website for Muthukaruppan Chettiar Jewellers.",
    isWebsite: true,
    link: "https://muthukaruppanchettiarjewellery.web.app/",
  },
  {
    id: 11,
    title: "Weather Master",
    subtitle: "Mobile Application",
    technologies: "React Native, Expo",
    description:
      "A simple mobile based application to get current weather in a relevant location and retrieve all the weather data from it.",
    isWebsite: true,
    link: null,
  },
];
