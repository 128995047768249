import React from "react";

function Footer() {
    return (
        <div className="footer" id="footer" >
            <p className="lead overline m-b-0">© 2024, Ishan Karunaratne. All Rights Reserved </p>
        </div>
    );
}

export default Footer;