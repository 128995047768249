import React from "react";

function About() {
    return (
        <div className="about text-white d-flex align-items-center" id="about" >
            <div className="container h-100" >
                <div className="row align-items-center justify-content-around">
                    <div className="col-md-4 d-flex align-items-center m-b-30 justify-content-center">
                        <div className="flip-image">
                            <div className="flip-image-inner">
                                <div className="flip-image-front">
                                    <img src="/assets/images/intro-2.jpg" alt="" className="about-photo" />
                                </div>
                                <div className="flip-image-back">
                                    <img src="/assets/images/intro.png" alt="" className="about-photo" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-7">
                        <h4 className="m-b-30">
                            Hi !<br />I am Ishan Karunaratne
                        </h4>
                        <p className="lead height-lg">
                            {/* I am a Software Engineer with hands-on experience in front-end
                            development, back-end development, UI/UX designing and all
                            levels of testing. A good self learner with the enthusiasm to
                            learn new technologies. */}
                            I am an Experienced Software Engineer with a strong background in designing
                            and implementing scalable solutions. Proficient in multiple programming languages
                            and frameworks, adept at collaborating with cross-functional teams to deliver
                            high-quality software products. Passionate about staying updated with emerging
                            technologies and leveraging them to drive innovation in software development.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;