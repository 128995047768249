import React, { useEffect, useRef, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProjectsPage from './ProjectsPage';
import { data } from './data';

function Projects() {

  const containerRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const container = containerRef.current;
    const scrollStep = 0.75; // adjust the scrolling speed here
    let scrollAmount = 0;
    let scrollDirection = 'right';
    let animationFrameId;

    const scroll = () => {
      if (scrollDirection === 'right') {
        scrollAmount += scrollStep;
        container.scrollLeft = scrollAmount;

        // Change direction when reaching the end
        if (scrollAmount >= container.scrollWidth - container.clientWidth) {
          scrollDirection = 'left';
        }
      } else {
        scrollAmount -= scrollStep;
        container.scrollLeft = scrollAmount;

        // Change direction when reaching the start
        if (scrollAmount <= 0) {
          scrollDirection = 'right';
        }
      }

      animationFrameId = requestAnimationFrame(scroll);
    };

    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        // Start scrolling when the parent div is in view
        animationFrameId = requestAnimationFrame(scroll);
      } else {
        // Stop scrolling when the parent div is out of view
        cancelAnimationFrame(animationFrameId);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    });

    observer.observe(container);

    // Clean up the observer and animation frame when the component unmounts
    return () => {
      observer.unobserve(container);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="projects text-white d-flex align-items-center" id="projects" >
      <div className="container h-100" >
        <h3 className="m-b-30">Projects</h3>
        <div className="row align-items-center justify-content-around">
          <div className="d-flex projects-section" id="projects-section" ref={containerRef}>
            {data.map((item, index) => (
              <div className="project-card" key={index}>
                <p className="lead">{item.title}</p>
                <p className="lg">{item.technologies}</p>
                <p className="sm m-b-40">( {item.subtitle} )</p>
                <p>{item.description}</p>
                {item.link &&
                  <a
                    href={item.link}
                    target="_blank" rel="noopener noreferrer"
                    className='d-flex menu-link text-decoration-none justify-content-center align-items-center'
                  >
                    {item.isWebsite ? 'Refer Website' : 'Try App'}
                    <span className="material-icons-round fs-20 m-l-10">
                      open_in_new
                    </span>
                  </a>
                }
              </div>
            ))}
          </div>

          <div className="view-all-button row d-flex flex-column text-center m-t-50">
            <p className='xl'>Enough of waiting for scrolling ?</p>
            <p className='menu-link text-decoration-none m-b-0' onClick={handleShow}>
              click here to view all
            </p>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement='top' name='top' className='projects-canvas'>
        <Offcanvas.Header closeButton closeVariant='white'>
          <h3 className='m-b-0'>Projects</h3>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ProjectsPage />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Projects;