export const data = [
  {
    id: 1,
    name: "University of Colombo",
    subName: "University of Colombo School of Computing",
    year: "2018-2021",
    detail1: "B.Sc. in Computer Science",
    detail2: "Graduated in July 2022",
  },

  {
    id: 2,
    name: "Taxila Central College, Horana",
    subName: "G.C.E. Advanced Level",
    year: "2014-2016",
    detail1: "Physical Science Stream (2016)",
    detail2: "Results : A B B",
  },
  {
    id: 3,
    name: "Prince of Wales College, Moratuwa",
    subName: "G.C.E. Ordinary Level",
    year: "2008-2013",
    detail1: "G.C.E. O/L (2013)",
    detail2: "Results : 9A's",
  },
  {
    id: 4,
    name: "British Council, Colombo 04",
    subName: "General English",
    year: "2016-2017",
    detail1: "Intermediate Level 3 Passed",
    detail2: "Upper Intermediate Level 1 Passed",
  },
];
