import React from "react";

function Page404() {
    return (
        <div className="not-found">
            <div className="error">404</div>
            <div className="error-sub m-b-20">Oops! The page you’re looking for doesn’t exist.</div>
            <div className="error-des m-b-20">You may have mistyped the address or the page may have been moved.</div>
            <a href="/" className="nav-home">
                Go to Home
                <span className="material-icons-round">home</span>
            </a>

        </div>
    );
}

export default Page404;