export const data = [
  {
    area: "Web Development",
    technologies: [
      "React",
      "Angular",
      "Node JS",
      "Next JS",
      "Vue JS",
      "Svelte",
      "HTML",
      "SCSS",
      "CSS",
      "Javascript",
      "Typescript",
      "Jquery",
      "Bootstrap",
      "Tailwind",
      "Shopify",
      "Webflow",
    ],
  },
  {
    area: "Mobile Development",
    technologies: ["React Native", "Ionic", "Svelte Native"],
  },

  {
    area: "Frontend Development",
    technologies: [
      "React",
      "React Native",
      "Angular",
      "Next JS",
      "Vue JS",
      "Svelte",
      "HTML",
      "SCSS",
      "CSS",
      "Javascript",
      "Typescript",
      "Jquery",
      "Bootstrap",
      "Tailwind",
      "Shopify",
      "Webflow",
    ],
  },

  {
    area: "Backend Development",
    technologies: [
      "Node JS",
      "PHP",
      "Javascript",
      "Typescript",
      "Laravel",
      "Shopify",
      "JavaEE",
    ],
  },
  {
    area: "Database",
    technologies: ["MySQL", "MongoDB", "Firebase"],
  },
  {
    area: "Programming",
    technologies: ["Javascript", "Typescript", "PHP", "C", "C++", "JavaEE"],
  },
  {
    area: "Design",
    technologies: [
      "Figma",
      "Adobe XD",
      "Justinmind",
      "Adobe Photoshop",
      "Adobe Lightroom",
    ],
  },
  {
    area: "Others",
    technologies: [
      "Redux",
      "NX",
      "Docker",
      "Jira",
      "ClickUp",
      "Open Project",
      "Github",
      "Monday",
      "Gulp",
      "Grunt",
    ],
  },
];
