import React, { useState } from "react";
import Intro from "../intro";
import Work from "../work";
import About from "../about";
import Education from "../education";
import Projects from "../projects";
import Expertise from "../expertise";
import Contact from "../contact";
import InquiryForm from "../inquiry";

function Home() {
    const [showInquiry, setShowInquiry] = useState(false);

    return (
        <div className="home-page">
            <Intro />
            <About />
            <Education />
            <Work />
            <Projects />
            <Expertise />
            <Contact />
            {showInquiry &&
                <InquiryForm show={showInquiry} handleShow={setShowInquiry} />
            }
            <div className="inquiry-show" onClick={() => setShowInquiry(!showInquiry)}>
                {!showInquiry ?
                    <span className="material-icons-round open-chat">forum</span>
                    :
                    <span className="material-icons-round close-chat">keyboard_arrow_down</span>

                }

            </div>

        </div>
    );
}

export default Home;