import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function NavbarMobile() {

    const [show, setShow] = useState(false);
    const [fileData, setFileData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        downloadFile();
    }, [])

    const downloadFile = async () => {
        try {
            const response = await fetch('/assets/downloads/resume.pdf');
            const blob = await response.blob();
            setFileData(blob);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className="nav-mobile">
            <div className='mobile d-flex justify-content-end'>
                <span className="material-icons-outlined" onClick={handleShow}>menu</span>
            </div>

            <Offcanvas show={show} onHide={handleClose} scroll={true} className='nav-mobile'>
                <Offcanvas.Body>
                    <Link to="intro" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} onClick={handleClose}>
                        Introduction
                    </Link>
                    <br />
                    {/* <Link to="about" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} onClick={handleClose}>
                        About Me
                    </Link>
                    <br/> */}
                    <Link to="education" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} onClick={handleClose}>
                        Education
                    </Link>
                    <br />
                    <Link to="work" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} onClick={handleClose}>
                        Work Experience
                    </Link>
                    <br />
                    <Link to="projects" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} onClick={handleClose}>
                        Projects
                    </Link>
                    <br />
                    <Link to="contact" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100} >
                        Contact Me
                    </Link>

                    <div onClick={downloadFile}>
                        {fileData && (
                            <a href={URL.createObjectURL(fileData)} download="Ishan Karunaratne - Resume.pdf" className="download-resume-mobile">
                                My Resume
                                <span className="material-icons-outlined">
                                    download
                                </span>
                            </a>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>


        </div>
    )
}


