import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import NavbarMobile from "./NavbarMobile";
function NavBar() {

    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        downloadFile();
    }, [])


    const downloadFile = async () => {
        try {
            const response = await fetch('/assets/downloads/resume.pdf');
            const blob = await response.blob();
            setFileData(blob);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className="nav-bar">
            <div className='desktop d-flex justify-content-center align-items-center m-t-10 m-b-0 relative'>
                <div className="menu-bar ul d-flex justify-content-around w-60">
                    <Link to="intro" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        Introduction
                    </Link>
                    {/* <Link to="about" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        About Me
                    </Link> */}
                    <Link to="education" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        Education
                    </Link>
                    <Link to="work" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        Work Experience
                    </Link>
                    <Link to="projects" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        Projects
                    </Link>
                    <Link to="contact" className="menu-link" activeClass='active' spy={true} smooth={true} offset={0} duration={100}>
                        Contact Me
                    </Link>

                    <div onClick={downloadFile}>
                        {fileData && (
                            <a href={URL.createObjectURL(fileData)} download="Ishan Karunaratne - Resume.pdf" className="download-resume">
                                My Resume
                                <span className="material-icons-outlined">
                                    download
                                </span>
                            </a>
                        )
                        }
                    </div>
                </div>
            </div>
            <NavbarMobile />
        </div>
    )
}

export default NavBar;