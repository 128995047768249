import React, { useState, useEffect } from "react";

function Intro() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Run your function here
            setShow(true);
        }, 3000);
        return () => {
            clearTimeout(timer); // Clear the timer if the component unmounts before 3 seconds
        };
    }, [])

    return (
        <div className="intro text-white d-flex align-items-center" id="intro" onClick={() => setShow((prevState) => !prevState)}>
            <div className="container h-100" >
                <div className="d-flex align-items-center">
                    <div className="intro-container all" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                        <h2 className="height-lg">I can</h2>
                        {show ? (
                            <div className="hidden-text">
                                <h3>play cricket,</h3>
                                <h3>watch movies,</h3>
                                <h3>&</h3>
                            </div>
                        ) : (null)}
                        <h2 className="height-lg">Develop</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intro;