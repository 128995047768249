import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import emailjs from "emailjs-com";

function InquiryForm({ show, handleShow }) {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [showForm, setShowForm] = useState(show);
    const [sendStatus, setSendStatus] = useState("send");

    useEffect(() => {
        setShowForm(show);
        return () => { };
    }, [show]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        setSendStatus("sending");
        e.preventDefault();
        submitData();
        sendEmail(e);
    };

    const handleClose = () => {
        setShowForm(false);
        handleShow(false);
    };

    const submitData = async () => {
        try {
            const response = await axios.post(
                `https://sheetdb.io/api/v1/t8cwcfiiotwi3`,
                formData
            );

            if (response.status === 201) {
                setSendStatus("sent");
                setTimeout(() => {
                    handleShow(false);
                }, 3000);
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    const sendEmail = (e) => {
        emailjs
            .sendForm(
                "ishansasika",
                "template_lx77cqe",
                e.target,
                "Pi4aYhs6dwerAPFXt"
            )
            .then(
                (result) => {
                    // console.log(result);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            {showForm && (
                <div className="inquiries animated-div">
                    <div className="inquiry-close" onClick={handleClose}>
                        <span className="material-icons-round">close</span>
                    </div>
                    <Form onSubmit={handleSubmit} className="inquiry-form">
                        <Form.Group controlId="formName">
                            <Form.Label className="form-label first-of-type">
                                Name:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label className="form-label">Email:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label className="form-label">Message:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Enter your message"
                                rows={3}
                                required
                            />
                        </Form.Group>
                        <div className="w-100 d-flex justify-content-center align-items-center m-t-20">
                            <Button
                                variant="success"
                                type="submit"
                                size="sm"
                                className="form-submit"
                            >
                                {sendStatus}
                                {sendStatus === "sending" ? (
                                    <img
                                        src="assets/images/progress.svg"
                                        alt="progress"
                                        className="animate-spin progress-spinner color-invert"
                                    />
                                ) : (
                                    <span className="material-icons-round rotate-y">send</span>
                                )}
                            </Button>
                        </div>
                    </Form>

                    {sendStatus === "sent" && (
                        <div className="success-alert">
                            <div className="success-message">
                                <p className="md">
                                    Thank you {formData.name} for contacting me.
                                    <br />I will get back to you soon !
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default InquiryForm;
