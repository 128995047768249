export const data = [
  {
    id: 1,
    role: "Software Engineer",
    company: "Revox Pvt Ltd",
    year: "Feb 2022 - Present",
    work: [
      "Web Development",
      "Mobile App Development",
      "Front-end Development",
      "Back-end Development",
      "Built Front-end templates",
      "Shopify Development",
      "Webflow Development",
      "Hosting",
      "API Documentation",
      "UI Fine Tuning",
      "Manual Testing",
      "Consulting and Supervising Interns",
    ],
  },
  {
    id: 2,
    role: "Software Developer",
    company: "Widya Pty Ltd",
    year: "Apr 2021 - Feb 2022",
    work: [
      "Direct UI Design and Web Development",
      "Web Application Development",
      "Front-end Development",
      "UI UX Design",
      "API Documentation",
      "Manual Testing",
      "Dockerizing Systems",
      "Consulting and Supervising Interns",
    ],
  },
  {
    id: 2,
    role: "Trainee Software Developer (Intern)",
    company: "Widya Pty Ltd",
    year: "Oct 2020 - Apr 2021",
    work: [
      "Direct UI Design and Web Development",
      "Web Application Development",
      "Front-end Development",
      "Followed Testing and Automation Course",
      "Followed Svelte Technology",
      "Manual Testing",
      "Daily Bug Fixes",
    ],
  },
];
